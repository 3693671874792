var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('PromotionAddEdit',{attrs:{"es-id":_vm.ae.esId,"primary-key":_vm.ae.primaryKey,"form-data":_vm.ae.formData,"is-editing":_vm.ae.isEditing,"fleet-currency":_vm.ae.fleetCurrency,"currency-symbol":_vm.ae.currencySymbol},on:{"save":function () {
        _vm.$edgeStack.shouldNotConfirm(_vm.ae.esId)
        _vm.$store.dispatch('fsTable/fetchData')
      },"close":function($event){return _vm.$edgeStack.shouldNotConfirm(_vm.ae.esId)}}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('TitlePlus',{attrs:{"title":_vm.$t('components.promoManagement.headline'),"hidePlus":_vm.$acl.canNotCreate('promotions')},on:{"plus":_vm.onOpenES}}),_c('div',{staticClass:"flex md:justify-end"},[_c('date-range-picker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)],1),_c('div',{staticClass:"summary-card-container"},[_c('SummaryCard',{attrs:{"title":_vm.$t('components.promoManagement.summary.currentPromo'),"value":_vm.indexMetaData.summary.current,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.promoManagement.summary.currentPromoUser'),"value":_vm.indexMetaData.summary.current_promo_users,"variant":"gray"}})],1)]),(_vm.$acl.canNotView('promotions'))?_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")]):_c('FSTable',{attrs:{"fst-id":"promotionIndex","headers":_vm.tableHeaders,"endpoint":_vm.indexDataEndpoint},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
      var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":"promotionIndex","slot-width":slotWidth,"options":_vm.getFilteredItems}})],1)]}},{key:"default",fn:function(ref){
      var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{ name: 'PromotionDetails', params: { id: item.id } }}}),_c('FSTableRowItem',{attrs:{"text":item.title}}),_c('FSTableRowItem',[(item.assigned_fleets && item.assigned_fleets.length > 0)?_c('div',{staticClass:"flex items-center gap-2 flex-wrap"},_vm._l((item.assigned_fleets),function(fleet,key){return _c('RouterLink',{key:key,staticClass:"text-blue-600 ",attrs:{"to":{ name: 'ViewFleetProfile', params: { id: fleet.id } },"target":"_blank"}},[_vm._v(_vm._s(fleet.name))])}),1):_vm._e()]),_c('FSTableRowItem',{attrs:{"text":_vm.getPromoTypeChoice(item.promotion_category)}}),_c('FSTableRowItem',{attrs:{"text":item.code,"class-name":"text-oOrange"}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm._f("friendlyDateTime")(item.end_date))+" "),_c('p',[_vm._v(" ("),_c('TimeAgo',{attrs:{"title":item.end_date,"datetime":item.end_date,"auto-update":60}}),_vm._v(") ")],1)]),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm.getPromoCurrencySymbol(item))+" "+_vm._s(parseFloat(item.max_amount).toFixed(2))+" ")]),_c('FSTableRowItem',{attrs:{"text":item.description,"truncate":30,"truncate-append":"..."}}),_c('FSTableRowItem',[_c('x-status',{attrs:{"text":item.status,"variant":_vm.getItemVariant(item.status)}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center gap-1"},[(item.status !== 'Expired')?_c('OtoEditIcon',{on:{"click":function($event){return _vm.onOpenES(item.id)}}}):_vm._e(),_c('MoreActions',{attrs:{"data":item},on:{"refresh":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])],1)})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }